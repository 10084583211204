import { datadogRum } from '@datadog/browser-rum';
import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {store} from './store';

datadogRum.init({
    applicationId: 'da86a4b8-fce4-4708-b55d-2cfaa9e628e0',
    clientToken: 'pub804d87827dd6b1b0c1aea587a9c786db',
    site: 'datadoghq.eu',
    service:'transport-web',
    env:'prod',
    // Specify a version number to identify the deployed version of your application in Datadog 
    // version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel:'mask-user-input',
    allowedTracingUrls: [(url) => url.startsWith("https://api.transport.swiftoffice.org")],
    enableExperimentalFeatures: ['clickmap']
});
datadogRum.startSessionReplayRecording();

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <App />
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
);

reportWebVitals();
